import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../../Layout";
import Seo from "../../../components/Seo/Seo";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import PageBanner from "../../../components/PageBanner/PageBanner";
import TitleSection from "../../../Molecules/TitleSection/TitleSection";
import ShapeBackground from "../../../Molecules/ShapeBackground/ShapeBackground";
import TextVisuelTwoCol from "../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol";
import TitlePage from "../../../Molecules/TitlePage/TitlePage";
import IntroPage from "../../../components/ContentType/IntroPage/IntroPage";
import ScrollPageNav from "../../../components/ScrollPageNav/ScrollPageNav";

import "./styles.scss";

var classNames = require("classnames");

const InnovationProjets = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "slider-image.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const zigzagColDatas1 = [
    {
      title: 'Vous cherchez une mission qui a du sens.',
      text: '<p>Les collaborateurs de Colas partagent tous les mêmes valeurs, la même culture, la même mission : imaginer, construire et entretenir des infrastructures de transport de façon responsable, à partir de leur ancrage local à travers le monde. Objets structurants du territoire, éléments de liaison entre les hommes et supports de leurs activités, les infrastructures de transports sont nos réseaux sociaux. Nous rejoindre, c’est participer au quotidien à construire la mobilité de demain.</p>',
      visuel: data.file.childImageSharp
    },
    {
      title: 'Vous cherchez une mission qui a du sens.',
      text: '<p>Les collaborateurs de Colas partagent tous les mêmes valeurs, la même culture, la même mission : imaginer, construire et entretenir des infrastructures de transport de façon responsable, à partir de leur ancrage local à travers le monde. Objets structurants du territoire, éléments de liaison entre les hommes et supports de leurs activités, les infrastructures de transports sont nos réseaux sociaux. Nous rejoindre, c’est participer au quotidien à construire la mobilité de demain.</p>',
      visuel: data.file.childImageSharp
    }
  ]
  const zigzagColDatas2 = [
    {
      title: 'Vous cherchez une mission qui a du sens.',
      text: '<p>Les collaborateurs de Colas partagent tous les mêmes valeurs, la même culture, la même mission : imaginer, construire et entretenir des infrastructures de transport de façon responsable, à partir de leur ancrage local à travers le monde. Objets structurants du territoire, éléments de liaison entre les hommes et supports de leurs activités, les infrastructures de transports sont nos réseaux sociaux. Nous rejoindre, c’est participer au quotidien à construire la mobilité de demain.</p>',
      visuel: data.file.childImageSharp
    },
    {
      title: 'Vous cherchez une mission qui a du sens.',
      text: '<p>Les collaborateurs de Colas partagent tous les mêmes valeurs, la même culture, la même mission : imaginer, construire et entretenir des infrastructures de transport de façon responsable, à partir de leur ancrage local à travers le monde. Objets structurants du territoire, éléments de liaison entre les hommes et supports de leurs activités, les infrastructures de transports sont nos réseaux sociaux. Nous rejoindre, c’est participer au quotidien à construire la mobilité de demain.</p>',
      visuel: data.file.childImageSharp
    },
    {
      title: 'Vous cherchez une mission qui a du sens.',
      text: '<p>Les collaborateurs de Colas partagent tous les mêmes valeurs, la même culture, la même mission : imaginer, construire et entretenir des infrastructures de transport de façon responsable, à partir de leur ancrage local à travers le monde. Objets structurants du territoire, éléments de liaison entre les hommes et supports de leurs activités, les infrastructures de transports sont nos réseaux sociaux. Nous rejoindre, c’est participer au quotidien à construire la mobilité de demain.</p>',
      visuel: data.file.childImageSharp
    },
    {
      title: 'Vous cherchez une mission qui a du sens.',
      text: '<p>Les collaborateurs de Colas partagent tous les mêmes valeurs, la même culture, la même mission : imaginer, construire et entretenir des infrastructures de transport de façon responsable, à partir de leur ancrage local à travers le monde. Objets structurants du territoire, éléments de liaison entre les hommes et supports de leurs activités, les infrastructures de transports sont nos réseaux sociaux. Nous rejoindre, c’est participer au quotidien à construire la mobilité de demain.</p>',
      visuel: data.file.childImageSharp
    },
    {
      title: 'Vous cherchez une mission qui a du sens.',
      text: '<p>Les collaborateurs de Colas partagent tous les mêmes valeurs, la même culture, la même mission : imaginer, construire et entretenir des infrastructures de transport de façon responsable, à partir de leur ancrage local à travers le monde. Objets structurants du territoire, éléments de liaison entre les hommes et supports de leurs activités, les infrastructures de transports sont nos réseaux sociaux. Nous rejoindre, c’est participer au quotidien à construire la mobilité de demain.</p>',
      visuel: data.file.childImageSharp
    }
  ]
  return (
    <Layout>
      <Seo title="Innovation N-2 Projets" description="N-2 Projets" />
      <div
        className={classNames(
          "page_template",
          "n-2_innovation_projets"
        )}
      >
        <ScrollPageNav
          data={[
            {
              title: 'Wattway',
              section: 'block_0_section'
            },
            {
              title: 'Vegecol',
              section: 'block_1_section'
            },
            {
              title: 'Reguly',
              section: 'block_2_section'
            },
            {
              title: 'BIM',
              section: 'block_3_section'
            }
          ]}
        />
        <PageBanner visuel={data.file.childImageSharp.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                grandParentPage: { title: "Acceuil", url: "/" },
                parentPage: { title: "Innovation", url: "/innovation" },
                currentPage: { title: "N-2 Projet", url: "/n-2" },
              }}
            />
            <TitlePage
              color="color_white"
              title="Plus de mobilité moins d’impact"
            />
          </div>
        </PageBanner>
        <section className={classNames("section_content", "block_0_section")}>
          <div className="wrapper_page">
            <TitleSection
              title="Wattway"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
              type="line"
            />
            <TextVisuelTwoCol
              orderInverse={false}
              extremeLeft={true}
              visuel={data.file.childImageSharp}
              text="<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>"
              cta="Lorem"
            />
          </div>
        </section>
        <section className={classNames("section_content", "block_1_section")}>
          <ShapeBackground top="left" color="catskill_white" bottomInfinite={true}>
            <div className="wrapper_page_xs">
              <TitleSection
                title="Vegecol"
              />
              {
                zigzagColDatas1.map((block, index) => (
                  <TextVisuelTwoCol
                    key={index}
                    orderInverse={index % 2 == 0 ? false : true}
                    extremeLeft={index === 0 ? true : false}
                    extremeRight={index % 2 == 0 ? false : true}
                    visuel={block.visuel}
                    title={block.title}
                    text={block.text}
                  />
                ))
              }
            </div>
          </ShapeBackground>
        </section>
        <section className={classNames("section_content", "block_2_section")}>
          <ShapeBackground top="left" color="bleu" bottom="right">
            <div className="wrapper_page_xs">
              <TitleSection
                title="Reguly"
                h2color="white"
              />
              {
                zigzagColDatas2.map((block, index) => (
                  <TextVisuelTwoCol
                    key={index}
                    orderInverse={index % 2 == 0 ? false : true}
                    extremeLeft={index === 0 ? true : false}
                    extremeRight={index % 2 == 0 ? false : true}
                    visuel={block.visuel}
                    title={block.title}
                    text={block.text}
                    colorTitle="color_white"
                    colorDescription="color_white"
                  />
                ))
              }
            </div>
          </ShapeBackground>
        </section>
        <section className={classNames("section_content", "block_3_section")}>
          <div className="wrapper_page_xs">
            <TitleSection
              title="BIM"
            />
            {
              zigzagColDatas2.map((block, index) => (
                <TextVisuelTwoCol
                  key={index}
                  orderInverse={index % 2 == 0 ? false : true}
                  extremeLeft={index === 0 ? true : false}
                  extremeRight={index % 2 == 0 ? false : true}
                  visuel={block.visuel}
                  title={block.title}
                  text={block.text}
                />
              ))
            }
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default InnovationProjets;
